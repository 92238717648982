
.homeImage>img {
    max-width: 350px;
    height: 5.5rem;
    padding: 0 65px;
    object-fit: cover;
    
}
img:last-of-type {
    padding-left: 0;
    
}

.inner {
    position: relative;
    width: 100%;
    filter: grayscale(100%);
    overflow: hidden;
    height: 5rem;
}

.wrapper {
    position: absolute;
    display: flex;
}

section {
    display: flex;
    animation: swipe var(--speed) linear infinite backwards;
}

@keyframes swipe {
    0% {
        transform: translate(0);
    }

    100% {
        transform: translate(-100%);
    }
}

@media screen and (max-width: 500px) {
   

    .homeImage>img {
        max-width: 200px;
        height: 4.5rem;
        padding: 0 15px;
        object-fit: cover;
        filter: grayscale(100%);
    }
}